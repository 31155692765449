// Add these additional actions and cases

import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    lang: "en",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder;
  },
});

export const uiAction = uiSlice.actions;
export default uiSlice;
