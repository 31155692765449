import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import store from "./store";
import { AuthAction } from "./store/slice/user";

function getInitialLanguage() {
  const lang = localStorage.getItem("lang");

  if (lang) {
    store.dispatch(AuthAction.setLang(lang));
    return decodeURIComponent(lang);
  }
  store.dispatch(AuthAction.setLang("en"));

  return "en";
}

function initI18n() {
  const initialLanguage = getInitialLanguage();

  i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      lng: initialLanguage,
      fallbackLng: "en",
      backend: {
        loadPath: "/locales/{{lng}}/translation.json",
      },
      interpolation: {
        escapeValue: false,
      },
    });
}

initI18n();

export default i18n;
