import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  unauthorizedAxios,
  authorizedAxios,
} from "../../config/axios/axios-config";
import {
  saveAuthData,
  clearAuthData,
  getAuthData,
} from "../../helpers/authStorage";

const API_URL = import.meta.env.VITE_LOGIN;
const API_USER = import.meta.env.VITE_GET_USER;
const API_REFRESH_TOKEN = import.meta.env.VITE_REFRESH_TOKEN;

const API_REGISTER = import.meta.env.VITE_REGISTER;
const API_RESEND_LINK = import.meta.env.VITE_RESEND_CONF;
const API_CONFIRM = import.meta.env.VITE_CONFIRM_EMAIL;
const PASSWORD_FORGET = import.meta.env.VITE_FORGET_PASSWORD;
const PASSWOR_RESET = import.meta.env.VITE_CHANGE_FORGET;
const API_2FA = import.meta.env.VITE_FA;

// Thunks for managing async local storage with localforage

export const loadAuthState = createAsyncThunk(
  "auth/loadAuthState",
  async () => {
    const authData = await getAuthData();
    if (authData) {
      const currentTime = new Date().getTime();
      if (authData.expiresIn < currentTime) {
        // Call the refresh token API if save is true
        const newAuthData = await refreshToken(authData.refreshToken);
        if (newAuthData) {
          // Update the auth data with new tokens
          const updatedAuthData = {
            ...authData,
            tokenType: newAuthData.tokenType,
            accessToken: newAuthData.accessToken,
            expiresIn: new Date().getTime() + newAuthData.expiresIn * 1000,
            refreshToken: newAuthData.refreshToken || authData.refreshToken,
          };
          await saveAuthData(updatedAuthData);
          return {
            ...updatedAuthData,
            isAuthenticated: true,
          };
        }

        await clearAuthData();
        return {
          user: null,
          tokenType: null,
          accessToken: null,
          expiresIn: null,
          refreshToken: null,
          isAuthenticated: false,
        };
      }
      return {
        ...authData,
        isAuthenticated: true,
      };
    }
    // No auth data found
    return {
      user: null,
      tokenType: null,
      accessToken: null,
      expiresIn: null,
      refreshToken: null,
      isAuthenticated: false,
    };
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue, dispatch }) => {
    const body = {
      email: credentials.email,
      password: credentials.password,
    };
    try {
      const response = await unauthorizedAxios.post(API_URL, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.data.accessToken) {
        throw new Error("This is an API error");
      }

      const { tokenType, accessToken, expiresIn, refreshToken } = response.data;
      const user = await dispatch(getUser(accessToken)).unwrap();
      const expirationTime = new Date().getTime() + expiresIn * 1000;

      await saveAuthData({
        tokenType,
        accessToken,
        expiresIn: expirationTime,
        refreshToken,
        user,
        save: credentials.rememberMe,
      });

      return {
        tokenType,
        accessToken,
        expiresIn: expirationTime,
        refreshToken,
        user,
      };
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return rejectWithValue({
            message: "unauthorized",
            status: 401,
            detail: error.response.data.detail || "Unknown error",
          });
        }
        return rejectWithValue({
          message: error.response.data.title || "unknownError",
          status: error.response.status,
          detail: error.response.data.detail || "Unknown error",
        });
      } else {
        return rejectWithValue({
          message: error.message || "networkError",
          status: "NETWORK_ERROR",
          detail: "Please check your internet connection.",
        });
      }
    }
  }
);

export const enable2FA = createAsyncThunk(
  "auth/enable2FA",
  async (
    {
      twoFactorCode,
      resetSharedKey = true,
      resetRecoveryCodes = true,
      forgetMachine = true,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await unauthorizedAxios.post(API_2FA, {
        enable: true,
        twoFactorCode,
        resetSharedKey,
        resetRecoveryCodes,
        forgetMachine,
      });

      if (response.status !== 200) {
        throw new Error("Failed to enable 2FA");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const logout = createAsyncThunk("auth/loadAuthState", async () => {
  await clearAuthData();
  return {
    user: null,
    tokenType: null,
    accessToken: null,
    expiresIn: null,
    refreshToken: null,
    isAuthenticated: false,
  };
});

export const register = createAsyncThunk(
  "auth/register",
  async (userData, { getState, rejectWithValue }) => {
    const lang = getState().auth.lang;
    const body = {
      Email: userData.email,
      Password: userData.password,
      LanguageId: lang,
      EmailConfirmationUrl: userData.baseUrl + "/login",
    };
    try {
      await unauthorizedAxios.post(API_REGISTER, body);
      return { success: true };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
//API_CONFIRM
export const confirm = createAsyncThunk(
  "auth/confirm",
  async ({ userID, code }, { rejectWithValue }) => {
    try {
      const response = await unauthorizedAxios.post(
        `${API_CONFIRM}?userId=${userID}&code=${code}`
      );

      if (response.status === 200) {
        if (!response.data) {
          return { success: true };
        }
        return response.data;
      } else {
        // Handle any unexpected status codes
        return rejectWithValue(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      // Handle 401 Unauthorized specifically
      if (error.response && error.response.status === 401) {
        return rejectWithValue("Unauthorized: Please check your credentials.");
      }
      // Handle any other errors
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "auth/register",
  async (userData, { getState, rejectWithValue }) => {
    const lang = getState().auth.lang;

    try {
      const url = `${API_RESEND_LINK}/${lang}?email=${encodeURIComponent(
        userData.email
      )}&emailConfirmationUrl=${encodeURIComponent(
        window.location.origin + "/login"
      )}`;
      await unauthorizedAxios.post(url);
      return { success: true };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/register",
  async (userData, { getState, rejectWithValue }) => {
    const lang = getState().auth.lang;
    const body = {
      Email: userData.email,
      LanguageId: lang,
      ConfirmChangePasswordUrl: userData.baseUrl,
    };
    try {
      const response = await unauthorizedAxios.post(PASSWORD_FORGET, body);
      return { success: true, ...response.data };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    const body = {
      email: userData.email,
      newPassword: userData.password,
      resetCode: userData.code,
    };
    try {
      const response = await unauthorizedAxios.post(PASSWOR_RESET, body);
      return { success: true, ...response.data };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getUser = createAsyncThunk(
  "auth/getUser",
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await authorizedAxios.get(API_USER, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const loadUser = createAsyncThunk(
  "user/loadUser",
  async (_, { rejectWithValue }) => {
    try {
      const authData = await getAuthData();
      if (!authData) throw new Error("No auth data found");
      return authData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const refreshToken = async (refreshToken) => {
  try {
    const response = await unauthorizedAxios.post(
      API_REFRESH_TOKEN,
      { refreshToken: refreshToken },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error refreshing token", error);
    return null;
  }
};
