import axios from "axios";
import { clearAuthData, getAuthData } from "../../helpers/authStorage";
import store from "../../store";
import { loadAuthState } from "../../store/thunks/userThunk";

const baseURL = import.meta.env.VITE_BASE_URL;

const baseConfig = {
  baseURL: baseURL,
  timeout: 60000,
  timestamp: new Date().getTime(),
};

const requestAuthInterceptor = async (config) => {
  try {
    let authData = await getAuthData();
    if (authData && authData.accessToken) {
      const currentTime = new Date().getTime();

      // Check if the token is expired or about to expire
      if (authData.expiresIn < currentTime + 5 * 60 * 1000) {
        // Token is about to expire or has expired, refresh it
        const newAuthData = await store.dispatch(loadAuthState()).unwrap();

        if (newAuthData && newAuthData.accessToken) {
          config.headers.Authorization = `Bearer ${newAuthData.accessToken}`;
          config.headers.token = `Bearer ${newAuthData.accessToken}`;
        } else {
          // await localforage.removeItem("authData");
          // window.location.href = LOGIN_URL;
          await clearAuthData();
          return config;
        }
      } else {
        // If the token is still valid, set the headers
        config.headers.Authorization = `Bearer ${authData.accessToken}`;
        config.headers.token = `Bearer ${authData.accessToken}`;
      }
    }
  } catch (error) {
    console.error("Error retrieving token from localforage", error);
  }
  return config;
};

const responseErrorInterceptor = (error) => {
  if (error.response && error.response.status === 401) {
    // Handle unauthorized error
    console.log("handle error");
    // window.location.href = LOGIN_URL;
  }
  return Promise.reject(error);
};

const authorizedAxios = axios.create(baseConfig);
const unauthorizedAxios = axios.create(baseConfig);

authorizedAxios.interceptors.request.use(requestAuthInterceptor);
authorizedAxios.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export { authorizedAxios, unauthorizedAxios };
