import { createSlice } from "@reduxjs/toolkit";
import {
  confirm,
  getUser,
  loadAuthState,
  login,
  register,
} from "../thunks/userThunk";

const lang = {
  fr: 1,
  en: 2,
  de: 3,
};

const initialState = {
  user: {
    name: null,
    surname: null,
    email: null,
    image: null,
    isEmailConfirmed: false,
  },
  tokenType: null,
  accessToken: null,
  expiresIn: null,
  refreshToken: null,
  isAuthenticated: false,
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  userName: null,
  userRole: null,
  userImage: null,
  restaurantID: null,
  restaurantName: null,
  restaurantImage: null,
  registerLoading: false,
  lang: 1,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = lang[action.payload];
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAuthState.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAuthState.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
        state.tokenType = action.payload.tokenType;
        state.accessToken = action.payload.accessToken;
        state.expiresIn = action.payload.expiresIn;
        state.refreshToken = action.payload.refreshToken;
        state.isAuthenticated = action.payload.isAuthenticated;
        state.restaurantID = action.payload.restaurantID;
        state.userName = action.payload.userName;
        state.userRole = action.payload.userRole;
        state.userImage = action.payload.userImage;
        state.restaurantImage = action.payload.restaurantImage;
        state.restaurantName = action.payload.restaurantName;
      })
      .addCase(loadAuthState.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { user, tokenType, accessToken, expiresIn, refreshToken } =
          action.payload;
        state.status = "succeeded";
        state.user.email = user.email;
        state.tokenType = tokenType;
        state.accessToken = accessToken;
        state.expiresIn = expiresIn;
        state.refreshToken = refreshToken;
        state.isAuthenticated = true;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(register.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.registerLoading = false;
        state.error =
          action.payload.message === "User with same email already exists"
            ? "userExist"
            : action.payload.message;
      })
      .addCase(confirm.pending, (state) => {
        state.registerLoading = true;
      })
      .addCase(confirm.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.error = null;
      })
      .addCase(confirm.rejected, (state, action) => {
        state.registerLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const { name, image, surname, email } = action.payload;
        state.user = {
          name: name || "",
          image: image || "",
          surname: surname || "",
          email: email || "",
        };
        state.error = "";
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload.message;
        state.isLoading = false;
      });
  },
});

export const AuthAction = authSlice.actions;

export default authSlice.reducer;
