import { useRef, useEffect } from "react";
import src from "./../../../assets/video/FULL_LOGO.mp4";
import styles from "./Video.module.scss";

const VideoPlay = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleEnded = () => {
      console.log("Video ended");
    };

    videoElement.addEventListener("ended", handleEnded);

    // Play the video when the component mounts
    videoElement.play();

    return () => {
      videoElement.removeEventListener("ended", handleEnded);
    };
  }, []);

  return (
    <div className={styles.videoContainer}>
      <video ref={videoRef} className={styles.video} muted autoPlay>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.overlay}></div>
    </div>
  );
};

export default VideoPlay;
