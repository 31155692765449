import { Outlet, useNavigate } from "react-router-dom";
//import LayoutImage from "./LayoutImage/LayoutImage";
import Video from "../../UI/Video/Video";
import styles from "./Layout.module.scss";
import { useEffect, useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { AuthAction } from "../../../store/slice/user";
import { useSelector, useDispatch } from "react-redux";
import i18n from "../../../i18n";
import { getAuthData } from "../../../helpers/authStorage";
import { confirm, logout } from "../../../store/thunks/userThunk";
import VideoPlay from "../../UI/Video/VideoPlay";

const languages = [
  { code: "fr", label: "FR", index: 1 },
  { code: "en", label: "EN", index: 2 },
  { code: "de", label: "DE", index: 3 },
];

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPlay, setIsPlay] = useState(true);
  const selectedLang = useSelector((state) => state.auth.lang);
  const { accessToken } = useSelector((state) => state.auth);
  const { width: windowWidth } = useWindowSize();

  // Reorder languages so that the selected one appears first
  const reorderedLanguages = languages.sort((a, b) => {
    if (a.index === selectedLang) return -1;
    if (b.index === selectedLang) return 1;
    return 0;
  });

  const handleLanguageChange = (langCode) => {
    localStorage.setItem("lang", langCode);
    i18n.changeLanguage(langCode);
    dispatch(AuthAction.setLang(langCode));
  };

  useEffect(() => {
    const confirmUser = async () => {
      const params = new URLSearchParams(window.location.search);

      // Extract each parameter by name
      const userID = params.get("userId");
      const code = params.get("code");

      if (userID && code) {
        try {
          await dispatch(confirm({ userID, code })).unwrap();
          params.delete("userId");
          params.delete("code");

          window.history.replaceState({}, "", `${window.location.pathname}`);
        } catch (error) {
          console.error("Confirmation failed:", error);
        }
      }
    };

    const handleAuthLogic = async () => {
      const params = new URLSearchParams(window.location.search);
      const isLogoutDev = params.get("logout");
      const authData = await getAuthData();

      if (isLogoutDev) {
        window.history.replaceState({}, "", `${window.location.pathname}`);
        await dispatch(logout()).unwrap();
        return;
      }

      if (authData && authData.accessToken && accessToken) {
        const encodedData = encodeURIComponent(JSON.stringify(authData));
        const url = window.location.origin;
        let baseUrl = "";
        if (!authData.user.isEmailConfirmed) {
          navigate("register-confirmation");
          return;
        }
        if (url === "https://localhost:5174") {
          baseUrl = `https://localhost:5175/manager/workplace?data=${encodedData}`;
        } else {
          baseUrl = `${window.location.origin}/manager/workplace`;
        }
        window.location.replace(`${baseUrl}`, "_blank");
      }
    };
    confirmUser();
    handleAuthLogic();
  }, [accessToken]);

  return (
    <main className={styles["login-container"]}>
      {windowWidth > 1024 && (
        <section className={styles["login-container__left-panel"]}>
          <VideoPlay />
        </section>
      )}
      {isPlay && windowWidth <= 1024 ? (
        <section className={styles["login-container__left-panel"]}>
          <Video windowWidth={windowWidth} setIsPlay={setIsPlay} />
        </section>
      ) : (
        <section className={styles["login-container__right-panel"]}>
          <div className={styles["login-container__language"]}>
            <span className={styles["login-container__language__name"]}>
              {reorderedLanguages[0].label}
            </span>
            <ul className={styles["login-container__language__list"]}>
              {reorderedLanguages.slice(1).map((language) => (
                <li key={language.code}>
                  <a
                    href="#"
                    onClick={() => handleLanguageChange(language.code)}
                  >
                    {language.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <section
            className={styles["login-container__right-panel__body-wrapper"]}
          >
            <Outlet />
          </section>
        </section>
      )}
      {/*</div>*/}
    </main>
  );
};

export default Layout;
