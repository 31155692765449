import { Suspense, lazy } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./i18n";

import useClearSessionOnUnload from "./hooks/useClearSessionOnUnload";
import Layout from "./Components/LoginRoot/Layout/Layout";

const Login = lazy(() => import("./page/Login/Login"));
const Register = lazy(() => import("./page/Login/Register"));
const RegisterConfirmation = lazy(() =>
  import("./page/Login/RegisterConfirmation")
);
const ResendEmail = lazy(() => import("./page/Login/ResendEmail"));
const Forgot = lazy(() => import("./page/Login/Forgot"));

const ResetPwd = lazy(() => import("./page/Login/ResetPwd"));

function App() {
  useClearSessionOnUnload();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<></>}>
              <Login />
            </Suspense>
          ),
        },
        {
          path: "/login",
          element: (
            <Suspense fallback={<div></div>}>
              <Login />
            </Suspense>
          ),
        },
        {
          path: "/register",
          element: (
            <Suspense fallback={<div></div>}>
              <Register />
            </Suspense>
          ),
        },
        {
          path: "/register-confirmation",
          element: (
            <Suspense fallback={<div></div>}>
              <RegisterConfirmation />
            </Suspense>
          ),
        },
        {
          path: "/resend-email",
          element: (
            <Suspense fallback={<div></div>}>
              <ResendEmail />
            </Suspense>
          ),
        },
        {
          path: "/forgot-password",
          element: (
            <Suspense fallback={<div></div>}>
              <Forgot />
            </Suspense>
          ),
        },
        {
          path: "/reset-password",
          element: (
            <Suspense fallback={<div></div>}>
              <ResetPwd />
            </Suspense>
          ),
        },
        {
          path: "*",
          element: <Navigate to="/login" replace />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
