import { useEffect } from "react";
import { clearSessionData } from "../helpers/authStorage";

const useClearSessionOnUnload = () => {
  useEffect(() => {
    // Set a flag in sessionStorage to indicate a refresh
    sessionStorage.setItem("isRefreshing", "true");

    const handleBeforeUnload = () => {
      if (sessionStorage.getItem("isRefreshing") === "false") {
        clearSessionData();
      }
    };

    const handleUnload = () => {
      sessionStorage.setItem("isRefreshing", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);
};

export default useClearSessionOnUnload;
