import { useEffect } from "react";
import PropTypes from "prop-types";

import src from "../../../assets/video/FULL_LOGO.webp";
import styles from "./Video.module.scss";

const AnimatedImage = ({ windowWidth, setIsPlay }) => {
  useEffect(() => {
    const animationDuration = 800;

    const handleEnded = () => {
      if (windowWidth <= 1024) {
        setIsPlay(false);
      }
    };

    const timeoutId = setTimeout(handleEnded, animationDuration);

    return () => {
      clearTimeout(timeoutId); // Cleanup the timeout if the component unmounts
    };
  }, [windowWidth, setIsPlay]);

  return (
    <div className={styles.videoContainer}>
      <img src={src} alt="FULL LOGO" className={styles.video} />
      <div className={styles.overlay}></div>
    </div>
  );
};

export default AnimatedImage;

AnimatedImage.propTypes = {
  windowWidth: PropTypes.number,
  setIsPlay: PropTypes.func,
};
