import localforage from "localforage";

const SESSION_KEY = "authData";
const TEMP_SESSION_KEY = "tempAuthData";

const saveAuthData = async (data) => {
  if (data.save) {
    await localforage.setItem(SESSION_KEY, data);
  } else {
    sessionStorage.setItem(TEMP_SESSION_KEY, JSON.stringify(data));
  }
};

const getAuthData = async () => {
  const tempAuthData = sessionStorage.getItem(TEMP_SESSION_KEY);
  if (tempAuthData) {
    return JSON.parse(tempAuthData);
  }
  return await localforage.getItem(SESSION_KEY);
};

const clearAuthData = async () => {
  sessionStorage.removeItem(TEMP_SESSION_KEY);
  await localforage.removeItem(SESSION_KEY);
};

const clearSessionData = () => {
  sessionStorage.removeItem(TEMP_SESSION_KEY);
};

export { saveAuthData, getAuthData, clearAuthData, clearSessionData };
